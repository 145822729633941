import React from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  Grid,
  Paper,
} from "@mui/material";
import { colors } from "../lib/theme";
import qrCodeImage from "./images/qr-code.png";
import sleeveImage from "./images/sleeve.svg";
import thumbGuideImage from "./images/thumb-guide.png";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../shared/useAuth";

const PreviewCard = ({
  cardTitle,
  setCardTitle,
  cardMessage,
  setCardMessage,
  senderClosing,
  setSenderClosing,
  uploadedImage,
  setUploadedImage,
  handlePrevious,
  generatedQuestions,
  additionalDetails,
  hobbiesInterests,
  favoriteMemory,
  location,
  relationship,
}) => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();

  window.scrollTo(0, 0);

  const gatherData = () => {
    return {
      cardTitle,
      cardMessage,
      senderClosing: senderClosing || "With the Golden Record App, you can record your memories, stories, and wisdom for generations to come. I can't wait to hear all the adventures and experiences that have made you the amazing person you are.",
      additionalDetails,
      uploadedImage: uploadedImage || null,
      generatedQuestions,
      hobbiesInterests,
      favoriteMemory,
      location,
      relationship,
    };
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddToCart = () => {
    const data = gatherData();
    localStorage.setItem("questionnaireData", JSON.stringify(data));
    navigate(user ? '/checkout' : '/login?from=questionnaire');
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Paper
        sx={{
          padding: 5,
          background: colors.darkerNavy,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.lightYellow,
              position: "relative",
            }}
          >
            {uploadedImage ? (
              <Box
                component="img"
                sx={{
                  width: 350,
                  height: 350,
                  transform: "rotate(-5deg)",
                  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                  zIndex: 2,
                }}
                alt="Uploaded"
                src={uploadedImage}
              />
            ) : (
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: 350,
                  height: 350,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.navy,
                  transform: "rotate(-5deg)",
                  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                  zIndex: 2,
                  border: 0,
                }}
              >
                Upload Image
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageUpload}
                />
              </Button>
            )}
            <Box
              sx={{
                display: { xs: "none", md: "block" }, // Hide on small screens
                position: "absolute",
                top: "120px",
                left: "50px",
              }}
            >
              <img
                src={sleeveImage}
                alt="Sleeve"
                style={{
                  transform: "rotate(-5deg)",
                  zIndex: 1,
                  width: 400,
                  height: 400,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box
              sx={{
                p: 3,
                backgroundColor: colors.navy,
                backgroundImage: `url(${thumbGuideImage})`,
                backgroundPosition: "100% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "40px 90px",
                color: "white",
                height: "100%",
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{ color: colors.black }}
              >
                <TextField
                  onChange={(e) => setCardTitle(e.target.value)}
                  value={cardTitle}
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 1, width: "100%", maxWidth: "100%" }}
                />
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ color: colors.black }}
              >
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  value={cardMessage}
                  variant="outlined"
                  onChange={(e) => setCardMessage(e.target.value)}
                  sx={{ width: "100%", maxWidth: "100%" }}
                />
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img
                  src={qrCodeImage}
                  alt="QR Code"
                  style={{ display: "block", width: "100%", maxWidth: "250px" }}
                />
                <Typography variant="body2" sx={{ color: 'white', flex: 1, fontSize: "1.1rem" }}>
                  <ul style={{ listStyleType: 'none', padding: '0 20px 0 0', margin: 0 }}>
                    {generatedQuestions.slice(0, 3).map((item, index) => (
                      <li key={item.id} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                        <div style={{
                          minWidth: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          backgroundColor: 'white',
                          color: colors.navy,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: '10px',
                          marginTop: '2px',
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }}>
                          {index + 1}
                        </div>
                        <span>{item.text}</span>
                      </li>
                    ))}
                  </ul>
                </Typography>
              </Box>

              <TextField
                fullWidth
                multiline
                rows={3}
                onChange={(e) => setSenderClosing(e.target.value)}
                placeholder="Your name here"
                variant="outlined"
                sx={{ mt: 1, width: "100%", maxWidth: "100%" }}
                value={
                  senderClosing ||
                  "With the Golden Record App, you can record your memories, stories, and wisdom for generations to come. I can't wait to hear all the adventures and experiences that have made you the amazing person you are."
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          onClick={handlePrevious}
          variant="text"
          sx={{ mt: 2, border: 0, color: colors.lightGray }}
        >
          Previous
        </Button>
        <Button
          variant="secondary"
          onClick={handleAddToCart}
          sx={{ mt: 2 }}
        >
          Add to Cart
        </Button>
      </Box>
    </Box>
  );
};

export default PreviewCard;
